<template>
  <div class="resourceActive">
    <el-card class="box-card">
      <el-form :inline="true" :model="dataForm" size="small">
        <el-form-item label="活动名称">
          <el-input v-model="dataForm.name" placeholder="请输入活动名称" />
        </el-form-item>

        <el-form-item>
          <el-button
            icon="el-icon-search"
            size="small"
            type="primary"
            round
            plain
            @click="queryTableList()"
            >查询
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <el-table
        :data="dataList"
        v-loading="dataListLoading"
        style="width: 100%"
        height="60vh"
        highlight-current-row
        stripe
      >
        <el-table-column prop="id" label="活动ID"> </el-table-column>
        <el-table-column prop="name" label="活动名称"> </el-table-column>
        <el-table-column prop="startTime" label="开始时间"> </el-table-column>
        <el-table-column prop="endTime" label="结束时间"> </el-table-column>
        <el-table-column label="活动类型">
          <template slot-scope="scope">
            <span> {{ $dict.activeType | dictFormat(scope.row.type) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="活动状态">
          <template slot-scope="scope">
            <span>
              {{ $dict.activeStatus | dictFormat(scope.row.status) }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="是否参加">
          <template slot-scope="scope">
            {{ $dict.joinStatus | dictFormat(scope.row.isHava) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <el-button
              size="small"
              type="primary"
              @click="goJoinActive(scope.row, 'add')"
              >我要参加
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="goJoinActive(scope.row, 'check')"
              >查看详情
            </el-button>
            <el-button
              size="small"
              type="text"
              @click="goApplicationRecord(scope.row)"
              >申请记录
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        style="float: right; padding: 10px 0"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="dataForm.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="dataForm.pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
const defaultParams = {
  name: '',
  pageSize: 10,
  pageNo: 1,
};
export default {
  name: 'resourceActive',
  data() {
    return {
      dataForm: Object.assign({}, defaultParams),
      dataListLoading: false,
      dataList: [],
      totalPage: 0,
    };
  },
  created() {
    this.queryTableList();
  },
  methods: {
    // 跳转到参加活动
    goJoinActive(row, type) {
      this.$router.push({
        path: 'joinActive',
        query: {
          row,
          type,
        },
      });
    },
    // 跳转到申请记录
    goApplicationRecord(row) {
      this.$router.push({
        path: 'applicationRecords',
        query: {
          row,
        },
      });
    },
    // 获取数据列表
    queryTableList() {
      this.dataListLoading = true;
      this.$api.active.reqActiveGoods(this.dataForm).then((res) => {
        if (res.code == 200) {
          this.dataList = res.data.records || [];
          this.totalPage = Number(res.data.total);
        } else {
          this.dataList = [];
          this.totalPage = 0;
          this.$message.error(res.message);
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.dataForm.pageSize = val;
      this.queryTableList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.dataForm.pageNo = val;
      this.queryTableList();
    },
    //重置参数
    resetParams() {
      this.params = Object.assign({}, defaultParams);
      setTimeout(() => {
        this.queryTableList();
      }, 100);
    },
    // 导出表格
    async confirmDownload() {
      let params = JSON.parse(JSON.stringify(this.params));
      params.pageSize = this.total;
      params.pageNo = 1;
      this.tableLoading = true;
      const { data } = await this.$api.supplierManagement.querySupplierList(
        params
      );
      let tHeader = [];
      let filterVal = [];
      this.filterHeader.forEach((item) => {
        tHeader.push(item.label);
        filterVal.push(item.prop);
      });
      const dataList = this.formatJson(filterVal, data);
      export_json_to_excel(tHeader, dataList, '供应商账户表');
      this.tableLoading = false;
    },
  },
};
</script>

<style scope></style>
